import React from "react";
import { Route, Switch } from "react-router";
import { CCookieBar } from "./customComponent/CCookieBar";
import HeaderSMD from "./commonComponent/header/HeaderSMD";
import HeaderSimpleSMD from "./commonComponent/header/HeaderSimpleSMD";
import FooterSMD from "./commonComponent/footer/FooterSMD";
import HomePage from "./pages/HomePage";
import FeedbackPage from "./pages/FeedbackPage";
import NotFoundPage from "./pages/NotFoundPage";
import PAnnullato from "./pages/PAnnullato";
import PPresoInCarico from "./pages/PPresoInCarico";
import InternalRedirect from "./pages/InternalRedirect";
import Privacy from "./pages/Privacy";

function Main() {
  return (
    <main>
      <Switch>
        <Route
          exact
          path="/tornahp"
          component={() => {
            window.location.href = "https://www.ospedalesanmartino.it";
            return null;
          }}
        />
        <Route
          path="/privacy"
          component={() => {
            window.location.href = "https://www.ospedalesanmartino.it/it/privacy-cookie-policy-donazioni";
            return null;
          }}
        />
        <Route
          path="/privacycookie"
          component={() => {
            window.location.href = "https://www.ospedalesanmartino.it/it/privacy-cookie-policy-donazioni";
            return null;
          }}
        />
        <Route exact path="/">
          <HeaderSMD />
          <div className="row mainBody">
            <HomePage />
          </div>
          <FooterSMD />
        </Route>
        <Route>
          <HeaderSimpleSMD />
          <div className="row mainBody">
            <Switch>
              <Route path="/redir/:toUrlE" component={InternalRedirect} />
              <Route path="/ppresoincarico/:idppp?/:mmm?/:ccc?" component={PPresoInCarico} />
              <Route path="/pannullato/:idppp?/:mmm?/:ccc?" component={PAnnullato} />
              <Route path="/feedback/:originFeedback/:typeFeedback/:titleFeedback/:subtitleFeedback/:messageFeedback" component={FeedbackPage} />
              <Route component={NotFoundPage} />
            </Switch>
          </div>
          <FooterSMD />
        </Route>
      </Switch>
      <CCookieBar />
    </main>
  );
}

export default Main;
