import React, { useEffect } from "react";
import { Container, Row, Col, Card } from "reactstrap";
import { withRouter } from "react-router-dom";

import redirecting from "../../../resources/redirecting.gif";

async function hasContent(toCheck) {
  return toCheck !== null && typeof toCheck !== "undefined" && toCheck.trim() !== "";
}

function InternalRedirect(props) {
  let toUrlE = props.match.params.toUrlE;
  let toUrl = hasContent(toUrlE) ? atob(toUrlE) : "-";

  useEffect(() => {
    async function redirectTo() {
      window.scrollTo(0, 0);
      console.log("toUrl");
      window.location = toUrl;
    }
    redirectTo();
  }, []);

  return (
    <div className="row col-12 d-flex flex-row">
      <Container className="px-4 my-4" id="donazione" tag="section">
        <Row tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
          <Col className="px-lg-4 py-lg-2 feedCol" lg={12} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
            <h1>
              Per favore attendere, redirect in corso...
              <br />
              <img className="img-fluid float-center" alt="redirecting" src={redirecting} title="redirecting" /> <br />
            </h1>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withRouter(InternalRedirect);
