import React from "react";
import { Container, Row, Col } from "reactstrap";
import CInput from "../customComponent/CInput";

function buildEmail(principale, props) {
  const _re = RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$");
  // const [_areEmailsEqual, setEmailsEqual] = useState(true);

  let _messaggioOk = "Valore corretto.";
  let _messaggioWarning = "L'email inserita non è corretta.";
  let _messaggioEmailDiverse = "Valori differenti.";

  if (principale === true) {
    return (
      <CInput
        id={props.idEmail === "" ? "inputEmail" : props.idEmail}
        maxLength={props.maxLength}
        name={props.nameEmail === "" ? "nameEmail" : props.nameEmail}
        label={"Email: ".toUpperCase()}
        type="email"
        value={props.email}
        handleNotifyValid={props.notifyRecapiti.notifyEmailValid}
        re={_re}
        onChange={props.onChangeEmail}
        isRequired={props.isRequired}
        messaggioOk={_messaggioOk}
        messaggioWarning={_messaggioWarning}
        validOnEnter={props.validOnEnter}
      />
    );
  } else {
    return (
      <CInput
        id={props.idEmailDiControllo === "" ? "inputEmailDiControllo" : props.idEmailDiControllo}
        maxLength={props.maxLength}
        name={props.nameEmailDiControllo === "" ? "nameEmailDiControllo" : props.nameEmailDiControllo}
        label={"Conferma Email: ".toUpperCase()}
        type="email"
        value={props.emailDiControllo}
        handleNotifyValid={props.notifyRecapiti.notifyEmailDiControlloValid}
        re={_re}
        onChange={props.onChangeEmailDiControllo}
        isRequired={props.isRequired}
        messaggioOk={_messaggioOk}
        messaggioWarning={_messaggioWarning}
        mainValue={props.email}
        messaggioValoriDiversi={_messaggioEmailDiverse}
        disableCopy={true}
        disablePaste={true}
        validOnEnter={true}
      />
    );
  }
}

function Email(props) {
  let _colsLeft = props.colsLeft;
  let _vaiACapoLoStesso = props.vaiACapoLoStesso;
  //<Row tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
  return (
    <>
      {_colsLeft === 12 && _vaiACapoLoStesso === false ? (
        <Col className="px-lg-4 py-lg-2" lg={_colsLeft} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
          {buildEmail(true, props)}
          {buildEmail(false, props)}
        </Col>
      ) : null}
      {_vaiACapoLoStesso === true ? (
        <>
          <Col className="px-lg-4 py-lg-2" lg={_colsLeft} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
            {buildEmail(true, props)}
          </Col>
          <Col className="px-lg-4 py-lg-2" lg={_colsLeft} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
            {buildEmail(false, props)}
          </Col>
        </>
      ) : null}
    </>
  );
}

export default Email;
