import React, { Component } from 'react';
import FeedbackPanel from '../commonComponent/FeedbackPanel';

class NotFoundPage extends Component {
  render() {
    return (
      <>
        <FeedbackPanel
          titleFeedback="Pagina non trovata"
          typeFeedback="danger"
          originFeedback=""
          subtitleFeedback="ATTENZIONE"
          messageFeedback="La risorsa cercata non &egrave; presente"
        />
      </>
    );
  }
}

export default NotFoundPage;
