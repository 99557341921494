import React from 'react';
import {
    Alert
} from 'design-react-kit'

function BoxFeedback(props) {
    if (props === null || props.data === null) {
        return null;
    }

    return (
        <>
            <h4>{props.titleFeedback}</h4><br />
            <Alert
                closeAriaLabel="Close"
                color={props.colorAlert}
                fade
                isOpen
                tag="div"
                transition={{
                    appear: true,
                    baseClass: 'fade',
                    baseClassActive: 'show',
                    enter: true,
                    exit: true,
                    in: true,
                    mountOnEnter: false,
                    onEnter: function noRefCheck() { },
                    onEntered: function noRefCheck() { },
                    onEntering: function noRefCheck() { },
                    onExit: function noRefCheck() { },
                    onExited: function noRefCheck() { },
                    onExiting: function noRefCheck() { },
                    tag: 'div',
                    timeout: 150,
                    unmountOnExit: true
                }}
            >
                <h4 className="alert-heading"> {props.originFeedback} </h4>
                <p> {props.subtitleFeedback}</p>
                <hr />
                <p className="mb-0"> {props.messageFeedback} </p>
            </Alert>
        </>
    )
}

export default BoxFeedback;