import React, { useEffect, useCallback } from "react";
import { Container, Row, Col, Card, CardTitle, CardBody } from "reactstrap";

import newLogoSM_solo_h67 from "../../../../resources/newLogoSM_solo_h67.png";
import newLogoSM_solo_h90 from "../../../../resources/newLogoSM_solo_h90.png";
import logopagppa_h67 from "../../../../resources/logopagppa_h67.png";
import logopagppa_h90 from "../../../../resources/logopagppa_h90.png";
function FooterSMD() {
  let divInLineStyle = {
    width: "80%",
    margin: "auto",
    height: "100%",
    msTransition: "all",
  };
  let imgInLineStyle = {
    margin: "2px",
  };
  let locationProtocol = window.location.protocol.toString();
  let locationTestoLink = window.location.host.toString();
  let locationAnchor = locationProtocol + "//" + locationTestoLink;
  if (locationTestoLink !== "donazionionline.ospedalesanmartino.it") {
    locationAnchor += "/smdonazioni";
    locationTestoLink += "/smdonazioni";
  }

  //alert(window.location.protocol + "//" + window.location.host);
  return (
    <Container>
      <Row tag="div" className="footerRowSM" widths={["xs", "sm", "md", "lg"]}>
        <Col className="px-lg-4 py-lg-2" lg={2} widths={["xs", "sm", "md", "lg"]}>
          <div className="img-responsive-wrapper">
            <picture>
              <source media="(min-width: 400px)" srcset={newLogoSM_solo_h90} type="image/png" />
              <source media="(min-width: 350px)" srcset={newLogoSM_solo_h67} type="image/png" />
              <img className="img-fluid float-center" alt="SanMartino" src={newLogoSM_solo_h67} title="SanMartino" />{" "}
            </picture>
          </div>
        </Col>
        <Col className="px-lg-4 py-lg-2" lg={8} widths={["xs", "sm", "md", "lg"]}>
          <Card className="footerCardSM">
            {" "}
            <h5>IRCCS Ospedale Policlinico San Martino</h5>
            <p>
              Largo R. Benzi 10 - 16132 Genova - P.I. e C.F. 02060250996
              <br />
              Email di contatto: <a href="mailto:donazioni@hsanmartino.it">donazioni@hsanmartino.it</a>
              <br />
              <a href="https://www.ospedalesanmartino.it/" target="_blank">
                ospedalesanmartino.it
              </a>
              <br />
              <a href={locationAnchor} target="_blank">
                {locationTestoLink}
              </a>
              <br />
            </p>
          </Card>
        </Col>
        <Col className="px-lg-4 py-lg-2" lg={2} widths={["xs", "sm", "md", "lg"]}>
          <div className="img-responsive-wrapper">
            <picture>
              <source media="(min-width: 400px)" srcset={logopagppa_h90} type="image/png" />
              <source media="(min-width: 350px)" srcset={logopagppa_h67} type="image/png" />
              <img className="img-fluid float-center" alt="PagoPA" src={logopagppa_h67} title="PagoPA" />{" "}
            </picture>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default FooterSMD;
