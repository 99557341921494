import React from "react";
import "../../styles/scss/bootstrap-italia-custom.scss";
import "typeface-titillium-web";
import "typeface-roboto-mono";
import "typeface-lora";
import Main from "./Main";
import { BrowserRouter } from "react-router-dom";
import "../../styles/App.css";

function App() {
  document.title = process.env.REACT_APP_TITLE;

  let locationBasename = process.env.REACT_APP_BASENAME;

  if (window.location.host !== process.env.REACT_APP_HOSTPUBBLICO) {
    locationBasename = process.env.REACT_APP_BASENAMENONPUBBLICO;
  }

  return (
    <BrowserRouter basename={locationBasename}>
      <div className="App">
        <Main />
      </div>
    </BrowserRouter>
  );
}

export default App;
