import axios from "axios";
import { API_GET_BASE_CONFIG, API_BASE_URL_SERVER } from "../common/AxiosConstant";

function removeDuplicates(list, property) {
  //switch to list ora array of properties
  if (!list[0].hasOwnProperty(property)) return list;
  let withoutDuplicates = list.filter((element, index) => {
    return (
      index ===
      list.findIndex((elem) => {
        return elem[property] === element[property];
      })
    );
  });
  return withoutDuplicates;
}
/*Response Schema
{
  // `data` is the response that was provided by the server
  data: {},

  // `status` is the HTTP status code from the server response
  status: 200,

  // `statusText` is the HTTP status message from the server response
  statusText: 'OK',

  // `headers` the HTTP headers that the server responded with
  // All header names are lower cased and can be accessed using the bracket notation.
  // Example: `response.headers['content-type']`
  headers: {},

  // `config` is the config that was provided to `axios` for the request
  config: {},

  // `request` is the request that generated this response
  // It is the last ClientRequest instance in node.js (in redirects)
  // and an XMLHttpRequest instance in the browser
  request: {}
}
*/
const apiCallGeneric = async (useThisConfig, recall = true) => {
  let toRet = null;
  try {
    await axios(useThisConfig)
      .then(function (response) {
        toRet = {
          data: response.data,
          code: "200",
          headers: response.headers,
        };
      })
      .catch(async function (error) {
        if (error.response) {
          if (error.response.status === 401 && recall) {
            sessionStorage.removeItem("sessionedAccessToken");
            await apiCallGeneric(useThisConfig, false);
          } else {
            toRet = {
              data: error.response.data,
              code: error.response.status,
            };
          }
        } else {
          toRet = {
            data: "Errore generico, contattare amministratore di sistema",
            code: "400",
          };
        }
      });
  } catch (e) {
    console.error("Axios send generic request failed: " + e);
  }
  return toRet;
};

//support function to call api using axios config by api internal key
async function callApi(keyConfig, baseUrlConfig = null, pathParams = null, queryParams = null, dataParams = null) {
  let axiosConfig = await getRightConfig(keyConfig, baseUrlConfig, pathParams, queryParams, dataParams);
  return await apiCallGeneric(axiosConfig, true);
}

//support function to get axios config by api internal key
async function getRightConfig(configIdentifier, baseUrlConfig = null, pathParams = null, queryParams = null, dataParams = null) {
  const configToRet = await API_GET_BASE_CONFIG(
    "", //await requestToken(),
    configIdentifier,
    baseUrlConfig,
    pathParams,
    queryParams,
    dataParams
  );
  return configToRet;
}

//API - GETTERS
export const getCausaliPagamento = async () => {
  let toRet = null;
  try {
    let responseDataLista = (await callApi("GET_CAUSALI_PAGAMENTO", API_BASE_URL_SERVER)).data.lista;
    toRet =
      responseDataLista !== null
        ? responseDataLista
            .map((singleData) => ({
              id: singleData.id,
              value: singleData.id,
              label: singleData.descrizione,
              ordinamento: singleData.iThOrder,
            }))
            .sort(function (a, b) {
              //sort by name
              if (a.ordinamento < b.ordinamento) {
                return -1;
              }
              if (a.ordinamento > b.ordinamento) {
                return 1;
              }
              return 0;
            })
        : null;
    console.log("response GET_CAUSALI_PAGAMENTO ", responseDataLista);
  } catch (e) {
    console.error("Axios getCausaliPagamento request failed: ", e);
  }
  return toRet;
};

export const getProvince = async () => {
  let toRet = null;
  try {
    let responseDataLista = (await callApi("GET_PROVINCE", API_BASE_URL_SERVER)).data.lista;
    toRet =
      responseDataLista !== null
        ? responseDataLista
            .map((singleData) => ({
              id: singleData.id,
              value: singleData.id,
              label: singleData.descrizione,
            }))
            .sort(function (a, b) {
              //sort by name
              if (a.label < b.label) {
                return -1;
              }
              if (a.label > b.label) {
                return 1;
              }
              return 0;
            })
        : null;
    console.log("response GET_PROVINCE ", responseDataLista);
  } catch (e) {
    console.error("Axios getProvince request failed: ", e);
  }
  return toRet;
};

export const getComuniByProvincia = async (provinciaValue) => {
  let toRet = null;
  try {
    let responseDataLista = (await callApi("GET_COMUNI_BY_PROVINCIA", API_BASE_URL_SERVER, Array.of(provinciaValue))).data.lista;
    toRet =
      responseDataLista !== null
        ? responseDataLista
            .map((singleData) => ({
              id: singleData.id,
              value: singleData.id,
              label: singleData.descrizione,
            }))
            .sort(function (a, b) {
              //sort by name
              if (a.label < b.label) {
                return -1;
              }
              if (a.label > b.label) {
                return 1;
              }
              return 0;
            })
        : null;
    console.log("response GET_COMUNI_BY_PROVINCIA ", responseDataLista);
  } catch (e) {
    console.error("Axios getComuniByProvincia request failed: ", e);
  }
  return toRet;
};

export const getFilePrivacy = async () => {
  let streamToRet = null;
  try {
    streamToRet = await callApi("GET_FILE_PRIVACY", API_BASE_URL_SERVER);
  } catch (e) {
    console.error("Axios getFilePrivacy request failed: ", e);
  }
  return streamToRet;
};

//API - SETTERS

//API - DELETE

//API RECAPTCHA - SETTERS

export const richiediPagamento = async (datiDaInviare) => {
  let response = null;
  try {
    response = await callApi("RICHIEDI_PAGAMENTO", API_BASE_URL_SERVER, null, null, datiDaInviare);
    console.log("response RICHIEDI_PAGAMENTO ", response);
  } catch (e) {
    console.error("Axios richiediPagamento request failed: ", e);
  }
  return response;
};

export const pagamentoOk = async (datiDaInviare) => {
  let response = null;
  try {
    response = await callApi("PAGAMENTO_OK", API_BASE_URL_SERVER, null, null, datiDaInviare);
    console.log("response PAGAMENTO_OK ", response);
  } catch (e) {
    console.error("Axios PAGAMENTO_OK request failed: ", e);
  }
  return response;
};

export const pagamentoKo = async (datiDaInviare) => {
  let response = null;
  try {
    response = await callApi("PAGAMENTO_KO", API_BASE_URL_SERVER, null, null, datiDaInviare);
    console.log("response PAGAMENTO_KO ", response);
  } catch (e) {
    console.error("Axios PAGAMENTO_KO request failed: ", e);
  }
  return response;
};
