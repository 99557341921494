import React from "react";
import CommonHeaderSMD from "./CommonHeaderSMD";
import foto_header_350 from "../../../../resources/foto_header_nb_350x.png";
import foto_header_400 from "../../../../resources/foto_header_nb_400x.png";
import foto_header_500 from "../../../../resources/foto_header_nb_500x.png";
import foto_header_610 from "../../../../resources/foto_header_nb_610x.png";
import foto_header_749 from "../../../../resources/foto_header_nb_749x.png";
import foto_header_1000x from "../../../../resources/foto_header_nb_1000x.png";
import foto_header_orig from "../../../../resources/foto_header_nb_orig.png";

import { Container, Row, Col, Card, CardTitle, CardBody } from "reactstrap";

function HeaderSMD() {
  return (
    <header className="app-header">
      <Container>
        <Row tag="div" widths={["xs", "sm", "md", "lg"]}>
          <Col className="px-lg-4 py-lg-2" lg={12} widths={["xs", "sm", "md", "lg"]}>
            <CommonHeaderSMD />
            <div className="img-responsive-wrapper img-responsive-panoramic">
              <picture>
                <img className="img-fluid float-center" alt="Foto Ricerca" src={foto_header_orig} title="Foto Ricerca" />{" "}
              </picture>
            </div>
            <Card className="headerCardSM">
              {" "}
              <h1>Sostieni la ricerca che cura: dona ora</h1>
            </Card>
          </Col>
        </Row>
      </Container>
    </header>
  );
}

export default HeaderSMD;
