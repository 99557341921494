import React from "react";
import { withRouter } from "react-router";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
//import "../../../styles/index.css"
import BoxFeedback from "../commonComponent/BoxFeedback";
import FeedbackPanel from "../commonComponent/FeedbackPanel";

async function hasContent(toCheck) {
  return toCheck !== null && typeof toCheck !== "undefined" && toCheck.trim() !== "";
}

function FeedbackPage(props) {
  let originFeedbackEnc = props.match.params.originFeedback;
  let typeFeedbackEnc = props.match.params.typeFeedback;
  let titleFeedbackEnc = props.match.params.titleFeedback;
  let subtitleFeedbackEnc = props.match.params.subtitleFeedback;
  let messageFeedbackEnc = props.match.params.messageFeedback;

  let originFeedback = hasContent(originFeedbackEnc) ? atob(originFeedbackEnc) : "Generica";
  let typeFeedback = hasContent(typeFeedbackEnc) ? atob(typeFeedbackEnc) : "Errore";
  let titleFeedback = hasContent(titleFeedbackEnc) ? atob(titleFeedbackEnc) : "Pagina di cortesia";
  let subtitleFeedback = hasContent(subtitleFeedbackEnc) ? atob(subtitleFeedbackEnc) : "Errore";
  let messageFeedback = hasContent(messageFeedbackEnc) ? atob(messageFeedbackEnc) : "Qualcosa è andato storto, contattare l'amministratore di sistema";

  return (
    <>
      <FeedbackPanel
        titleFeedback={titleFeedback}
        typeFeedback={typeFeedback}
        originFeedback={originFeedback}
        subtitleFeedback={subtitleFeedback}
        messageFeedback={messageFeedback}
      />
    </>
  );
}

export default withRouter(FeedbackPage);
