import React from "react";
import newLogoSM_350x153 from "../../../../resources/newLogoSM_350x153.png";
import newLogoSM_400x175 from "../../../../resources/newLogoSM_400x175.png";
import newLogoSM_500x219 from "../../../../resources/newLogoSM_500x219.png";
import newLogoSM_610x267 from "../../../../resources/newLogoSM_610x267.png";
import newLogoSM_749x328 from "../../../../resources/newLogoSM_749x328.png";
import newLogoSM_1000x438 from "../../../../resources/newLogoSM_1000x438.png";
import logo_largo_3 from "../../../../resources/logo_largo_3.png";

function CommonHeaderSMD() {
  return (
    <div className="img-responsive-wrapper">
      {" "}
      <picture>
        <img className="img-fluid float-center" alt="Logo Ospedale San Martino" src={logo_largo_3} title="Logo Ospedale San Martino" />{" "}
      </picture>
    </div>
  );
}

export default CommonHeaderSMD;
