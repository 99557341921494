function getValue(field) {
  return field !== null && typeof field !== "undefined" ? field.value : "";
}

export async function buildDataInvioPagamento(inputData, acconsentoPerIE) {
  let acconsentoDonatore = getValue(inputData.acconsentoDonatore);
  if (acconsentoDonatore === null || typeof acconsentoDonatore === "undefined" || acconsentoDonatore !== "SI" || acconsentoDonatore !== "NO") {
    acconsentoDonatore = acconsentoPerIE;
  }
  return {
    codiceFiscaleDonatore: getValue(inputData.codiceFiscaleDonatore),
    cognomeDonatore: getValue(inputData.cognomeDonatore),
    nomeDonatore: getValue(inputData.nomeDonatore),
    emailDonatore: getValue(inputData.emailDonatore),
    telefonoDonatore: getValue(inputData.telefonoDonatore),
    importoDaDonare: getValue(inputData.importoDaDonare),
    idCausaleDonazione: getValue(inputData.causaleDonazione),
    indirizzoDonatore: getValue(inputData.indirizzoDonatore),
    codProvinciaDonatore: getValue(inputData.provinciaDonatore),
    codComuneDonatore: getValue(inputData.comuneDonatore),
    area: getValue(inputData.areaindicata),
    presoVisioneDonatore: getValue(inputData.presoVisioneDonatore) === "on" ? "SI" : "NO",
    acconsentoDonatore: acconsentoDonatore,
  };
}
