import qs from "qs";

//todo: get base url from react proxy config
export const API_BASE_URL_ESTERNO = "http://esterno.it";

export const API_BASE_URL_SERVER = "";

export const API_BASE_CONFIG = function (baseUrlConfig = null) {
  if (baseUrlConfig === null) baseUrlConfig = API_BASE_URL_ESTERNO;
  let objToReturn = {
    baseURL: baseUrlConfig,
  };
  return objToReturn;
};

const mappingNameUrlApi = [
  {
    method: "post",
    identifier: "RICHIEDI_PAGAMENTO",
    url: process.env.PUBLIC_URL + "/pagamento/richiedi",
    suffix: "",
    responseType: "json",
  },
  {
    method: "post",
    identifier: "PAGAMENTO_OK",
    url: process.env.PUBLIC_URL + "/pagamento/rispostaok",
    suffix: "",
    responseType: "json",
  },
  {
    method: "post",
    identifier: "PAGAMENTO_KO",
    url: process.env.PUBLIC_URL + "/pagamento/rispostako",
    suffix: "",
    responseType: "json",
  },
  {
    method: "get",
    identifier: "GET_CAUSALI_PAGAMENTO",
    url: process.env.PUBLIC_URL + "/tipologiche/causali",
    suffix: "",
    responseType: "json",
  },
  {
    method: "get",
    identifier: "GET_PROVINCE",
    url: process.env.PUBLIC_URL + "/tipologiche/province",
    suffix: "",
    responseType: "json",
  },
  {
    method: "get",
    identifier: "GET_COMUNI_BY_PROVINCIA",
    url: process.env.PUBLIC_URL + "/tipologiche/comuniprovincia",
    suffix: "",
    responseType: "json",
  },
  {
    method: "get",
    identifier: "GET_FILE_PRIVACY",
    url: process.env.PUBLIC_URL + "/media/download/privacystreaming",
    suffix: "",
    responseType: "blob",
  },
];

export const API_GET_BASE_CONFIG = async (accessToken, apiIdentifier, baseUrlConfig = API_BASE_URL_ESTERNO, pathParams = null, queryParams = null, dataParams = null) => {
  let apiToCall = Array.from(mappingNameUrlApi);
  apiToCall = apiToCall.filter((x) => x.identifier === apiIdentifier)[0];
  let urlToCall = apiToCall.url;
  let methodToCall = apiToCall.method;
  let responseTypeApi = apiToCall.responseType;

  //add path params to url
  if (pathParams !== null && typeof pathParams !== undefined) {
    await pathParams.forEach((element) => (urlToCall += "/" + element));
  }
  //add query params to url
  if (queryParams !== null && typeof queryParams !== undefined) {
    console.log("queryParams:", queryParams);
    urlToCall += "?"; //nome=pippo&cognome=cane
    queryParams.forEach((element) => (urlToCall += "" + element.key + "=" + element.value + "&"));
  }
  //add suffix
  if (apiToCall.suffix !== null && typeof apiToCall.suffix !== undefined) {
    let suffixToCall = apiToCall.suffix;
    urlToCall += suffixToCall;
  }

  // if (responseTypeApi === null || typeof responseTypeApi === "undefined") {
  //   responseTypeApi = "json";
  //  }

  let toRet = {
    ...API_BASE_CONFIG(baseUrlConfig),
    ...{
      url: urlToCall,
      method: methodToCall,
      data: dataParams !== null && typeof dataParams !== undefined ? dataParams : "",
      headers: {
        "Content-Type": "application/json", //,
        //"Authorization": "Bearer " + accessToken
      },
      responseType: responseTypeApi,
    },
  };

  console.log("aaaaa", toRet);
  return toRet;
};
