import React, { useCallback, useEffect, useState } from "react";
import { Input } from "design-react-kit";

function CInput(props) {
  const [_isValid, setIsValid] = useState(null);
  const [_messaggioWarningToRender, setMessaggioWarningToRender] = useState(null);

  let _messaggioOk = props.messaggioOk === null ? "Valore corretto." : props.messaggioOk;

  let _messaggioWarning = props.messaggioWarning === null ? "Il valore inserito non è corretto." : props.messaggioWarning;

  let _messaggioValoriDiversi = props.messaggioValoriDiversi === null ? "I valori non corrispondono." : props.messaggioValoriDiversi;
  let _value = props.value !== null ? props.value : "";

  const checkValue = useCallback(
    (value) => {
      async function intFun(valuetoCheck) {
        let isValid = true;
        if (valuetoCheck !== null && valuetoCheck !== "") {
          isValid = props.re.test(valuetoCheck);
          if (isValid && props.mainValue !== null && typeof props.mainValue !== "undefined") {
            if (props.mainValue !== valuetoCheck) {
              setMessaggioWarningToRender(_messaggioValoriDiversi);
              isValid = false;
            } else {
              setMessaggioWarningToRender(_messaggioWarning);
            }
          }
        } else if (props.isRequired === false && (valuetoCheck === null || valuetoCheck === "")) {
          isValid = null;
        } else if (props.isRequired === true && (valuetoCheck === null || valuetoCheck === "")) {
          isValid = false;
        }
        setIsValid(isValid);
        props.handleNotifyValid(isValid);
      }
      intFun(value);
    },
    [_messaggioValoriDiversi, _messaggioWarning, props]
  );

  useEffect(() => {
    if (props.validOnEnter === true) {
      checkValue(_value);
    }
  }, [props.validOnEnter]);

  /*useCallback(() => {
    async function checkValue(valuetoCheck) {
    let isValid = props.re.test(valuetoCheck);
    if (isValid && ((props.mainValue !== null && typeof (props.mainValue) !== 'undefined'))) {
        if (props.mainValue !== valuetoCheck) {
            setMessaggioWarningToRender(_messaggioValoriDiversi);
            isValid = false;
        }
        else {
            setMessaggioWarningToRender(_messaggioWarning);
        }
    }
    setIsValid(isValid);
    props.handleNotifyValid(isValid);
    }*/

  useEffect(() => {
    setMessaggioWarningToRender(_messaggioWarning);
  }, [_messaggioWarning]);
  /*
        useEffect(() => {
            async function getProvince() {
                if (props.value !== null && typeof (props.value) !== 'undefined') {
                    await checkValue(props.value);
                }
            }
            getProvince();
        }, [props.value, props.mainValue, checkValue]);
    */
  function onCopyNorthing(e) {
    e.preventDefault();
    return "";
  }

  function onPasteNorthing(e) {
    e.preventDefault();
    return "";
  }

  function getInfoText() {
    //should be null
    if (_isValid === true) return _messaggioOk;
    else if (_isValid === false) return _messaggioWarningToRender;
  }

  function getIsValid() {
    return _isValid !== null ? _isValid : undefined;
  }

  function getIsInvalid() {
    return _isValid !== null ? !_isValid : undefined;
  }

  //messo  onKeyUpCapture={(e) => {
  //al posto di onBlurCapture

  return (
    <Input
      id={props.id}
      maxLength={props.maxLength !== null && typeof props.maxLength !== "undefined" ? props.maxLength : "100"}
      name={props.name}
      label={props.label + (props.isRequired ? " *" : "")}
      type={props.type}
      value={_value}
      onKeyUpCapture={(e) => {
        checkValue(e.target.value);
      }}
      onChange={(event) => {
        props.onChange(event);
      }}
      required={props.isRequired}
      valid={getIsValid()}
      invalid={getIsInvalid()}
      infoText={getInfoText()}
      // contextMenuHidden={true}
      // {...myAttSr}
      onCopy={(e) => {
        if (props.disableCopy === true) onCopyNorthing(e);
      }}
      onPaste={(e) => {
        if (props.disablePaste === true) onPasteNorthing(e);
      }}
    />
  );
}

export default CInput;
