import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { Button, Icon, Input, FormGroup, Toggle, Label, Progress, CardText, CardSignature } from "design-react-kit";
import { Container, Row, Col, Card } from "reactstrap";
import { Redirect } from "react-router";
import CInput from "../customComponent/CInput";
import CSelect from "../customComponent/CSelect";
import { buildDataInvioPagamento } from "../../businesslogic/Wrapper";
import { richiediPagamento, getCausaliPagamento, getProvince, getComuniByProvincia, getFilePrivacy } from "../../businesslogic/ApiInterface";
import { Link } from "react-router-dom";

//import "../../../styles/index.css";
import Email from "../commonComponent/Email";

function HomePage() {
  const _reCf = RegExp("^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$");
  const _reTel = RegExp("^\\d{5,13}$");

  let divButtonInLineStyle = {
    "margin-top": "20px",
    "margin-bottom": "25px",
  };

  let _messaggioCfOk = "Codice fiscale formalmente corretto.";
  let _messaggioCfWarning = "Il codice fiscale inserito non è corretto.";

  let _messaggioTelOk = "Telefono formalmente corretto.";
  let _messaggioTelWarning = "Il telefono inserito non è corretto [da 5 a 13 cifre senza spazi]";

  const [_nome, setNome] = useState(sessionStorage.getItem("_nome"));
  const [_cognome, setCognome] = useState(sessionStorage.getItem("_cognome"));
  const [_altro, setAltro] = useState(sessionStorage.getItem("_altro"));
  const [_codiceFiscale, setCodiceFiscale] = useState(sessionStorage.getItem("_codiceFiscale"));
  const [_email, setEmail] = useState(sessionStorage.getItem("_email"));
  const [_emailDiControllo, setEmailDiControllo] = useState("");
  const [_telefono, setTelefono] = useState(sessionStorage.getItem("_telefono"));
  const [_importo, setImporto] = useState("");
  const [_causale, setCausale] = useState(null);
  const [_indirizzo, setIndirizzo] = useState(sessionStorage.getItem("_indirizzo"));
  const [_provincia, setProvincia] = useState(null);
  const [_comune, setComune] = useState(null);

  const [_richiestaOk, setRichiestaOk] = useState(null);
  const [_isCodiceFiscaleValid, setCodiceFiscaleValid] = useState(false);
  const [_isTelefonoValid, setTelefonoValid] = useState(true); //parto true perche not required
  const [_pathToRedirectOk, setPathToRedirectOk] = useState(null);
  const [_isEmailValid, setEmailValid] = useState(null);
  const [_isEmailDiControlloValid, setEmailDiControlloValid] = useState(null);

  //Stati
  const [_itemsCausaleDonazione, setItemsCausaleDonazione] = useState([{ label: "Loading ...", value: "" }]);
  const [_loadedItemsCausaleDonazione, setLoadedItemsCausaleDonazione] = useState(false);
  //Stati
  const [_itemsProvince, setItemsProvince] = useState([{ label: "Loading ...", value: "" }]);
  const [_loadedItemsProvince, setLoadedItemsProvince] = useState(false);
  //Stati
  const [_itemsComuni, setItemsComuni] = useState([{ label: "Loading ...", value: "" }]);
  const [_loadedItemsComuni, setLoadedItemsComuni] = useState(false);

  const [_labelBottoneDona, setLabelBottoneDona] = useState(" Dona Ora ".toUpperCase());

  const [_abilitatoBottonePrivacy, setAbilitaBottonePrivacy] = useState(false);
  const [_presoVisione, setPresoVisione] = useState(false);
  const [_acconsentoPerIE, setAcconsentoPerIE] = useState(null);

  //const [_responsePdfPrivacy, setResponsePdfPrivacy] = useState(null);

  // const [_scaricatoPdfPrivacy, setScaricatoPdfPrivacy] = useState(false);
  //const [_mostraAccettaPrivacy, setMostraAccettaPrivacy] = useState(true);
  // const [_isPrivacyAccettata, setIsPrivacyAccettata] = useState(false);

  useEffect(() => {
    async function _getCausaliPagamento() {
      let causali = await getCausaliPagamento();
      setItemsCausaleDonazione(causali);
      setLoadedItemsCausaleDonazione(true);
    }
    async function _getProvince() {
      let province = await getProvince();
      setItemsProvince(province);
      setLoadedItemsProvince(true);
    }
    /* async function _getResponsePdfPrivacy() {
      setResponsePdfPrivacy(await Promise.resolve(getFilePrivacy()));
    }*/
    _getCausaliPagamento();
    _getProvince();
    handleAcconsento("SI");
    // _getResponsePdfPrivacy();
  }, []);

  useEffect(() => {
    async function _setCausale() {
      if (_loadedItemsCausaleDonazione === true) {
        setCausale(sessionStorage.getItem("_causale"));
      }
    }
    _setCausale();
  }, [_loadedItemsCausaleDonazione]);

  /*  useEffect(() => {
    if (_responsePdfPrivacy !== null) {
      setAbilitaBottonePrivacy(_responsePdfPrivacy.code === "200");
    }
  }, [_responsePdfPrivacy]);
*/
  /* useEffect(() => {
    if (_scaricatoPdfPrivacy !== false) {
      setMostraAccettaPrivacy(true);
    }
  }, [_scaricatoPdfPrivacy]);
*/
  useEffect(() => {
    async function _getComuni() {
      if (_provincia !== null && typeof _provincia !== "undefined") {
        setItemsComuni(_itemsComuni);
        let comuni = await getComuniByProvincia(_provincia.value);
        setItemsComuni(comuni);
        setLoadedItemsComuni(true);
      }
    }
    _getComuni();
  }, [_provincia]);

  const notifyCodiceFiscaleValid = useCallback((value) => {
    setCodiceFiscaleValid(value);
  }, []);

  const notifyTelefonoValid = useCallback((value) => {
    setTelefonoValid(value);
  }, []);

  //almeno 1 euro
  const checkImporto = useCallback((item) => {
    if (item > 0) setImporto(item);
    else setImporto(1);
  }, []);

  //const downloadPrivacy = useCallback(() => {
  // async function downloadPrivacy() {
  //  if (_abilitatoBottonePrivacy) {
  /*  const [, contentType] = _responsePdfPrivacy.headers["content-type"];
      let contentTypeGet = contentType === null ? "application/pdf" : contentType;

      const [, contentDisposition] = _responsePdfPrivacy.headers["content-disposition"];
      console.log("contentDisposition OK: ", contentDisposition);
      let contentDispositionFileName =
        contentDisposition === null ? "PrivacyDonazioniSanMartino.pdf" : contentDisposition.substr(contentDisposition.lastIndexOf("=") + 1).split(" ");

      //let contentDispositionFileName = contentDisposition === null ? "DonazioniSanMartino.pdf" : contentDisposition.split('filename=');
      console.log("contentType OK: ", contentType);
      console.log("contentTypeGet OK: ", contentTypeGet);
      console.log("contentDispositionFileName OK: ", contentDispositionFileName);
*/
  /*     let contentTypeEmb = "application/pdf";
      let contentDispositionFileNameEmb = "PrivacyDonazioniSanMartino.pdf";
      let contentDispositionFileName = "";
      const [, filename] = _responsePdfPrivacy.headers["content-disposition"].split("filename=");
      if (filename === null) {
        contentDispositionFileName = contentDispositionFileNameEmb;
      } else {
        contentDispositionFileName = filename;
      }
      //const file = new Blob([_responsePdfPrivacy.data], { type: contentTypeEmb });
      //  const fileCreated = new File([_responsePdfPrivacy.data], contentDispositionFileNameEmb, { type: contentTypeEmb });
      const fileCreated2 = new Blob([_responsePdfPrivacy.data], { type: contentTypeEmb });
      const pdfURL2 = window.URL.createObjectURL(fileCreated2);
      let tempLink = document.createElement("a");
      tempLink.href = pdfURL2;
      tempLink.setAttribute("download", contentDispositionFileName);
      tempLink.click();
      setScaricatoPdfPrivacy(true);
    }
  }*/
  /*    getPdf();
    setScaricatoPdfPrivacy(true);
  }, []);
*/
  const notifyEmailValid = useCallback((value) => setEmailValid(value), []);

  const notifyEmailDiControlloValid = useCallback((value) => setEmailDiControlloValid(value), []);

  let notifyRecapiti = {
    notifyEmailValid: notifyEmailValid,
    notifyEmailDiControlloValid: notifyEmailDiControlloValid,
  };

  const handleSubmit = useCallback((evtInput) => {
    async function inviaPagamento(evt, acconsentoPerIE) {
      sessionStorage.removeItem("_acconsentoPerIE");
      // if (_abilitatoBottonePrivacy === true && _mostraAccettaPrivacy === true && _isPrivacyAccettata === true) {
      evt.preventDefault();
      setLabelBottoneDona("Attendere...");
      let dataTarget = evt.target;
      //  console.log("evt: ", evt);
      let dataInvioPagamento = await buildDataInvioPagamento(dataTarget, acconsentoPerIE);
      // console.log("dati invio pagamento: ", dataInvioPagamento);
      let responseInvioPagamento = await richiediPagamento(dataInvioPagamento);

      if (responseInvioPagamento !== null && responseInvioPagamento.data !== null) {
        if (responseInvioPagamento.code === "200") {
          //alert("aa ", responseInvioPagamento.data);
          //setRichiestaOk(true);
          window.scrollTo(0, 0);
          let externalLink =
            //responseInvioPagamento.data.idPagamento;
            responseInvioPagamento.data.urlPagamentoImmediatoToRedirect + responseInvioPagamento.data.iuv;
          //responseInvioPagamento.data.descrizione;

          let toPath = "./redir/" + btoa(externalLink);
          setPathToRedirectOk(toPath);
        } else {
          setRichiestaOk(false);
        }
      }
      // }
    }
    inviaPagamento(evtInput, sessionStorage.getItem("_acconsentoPerIE"));
  }, []);

  const handleCausale = useCallback((item) => {
    setCausale(item);
  }, []);
  const handleProvincia = useCallback((item) => {
    setProvincia(item);
    setComune(null);
  }, []);
  const handleComune = useCallback((item) => {
    setComune(item);
  }, []);

  const handlePresoVisione = useCallback((value) => {
    setPresoVisione(value);
  }, []);
  async function handleAcconsento(value) {
    setAcconsentoPerIE(value);
    sessionStorage.setItem("_acconsentoPerIE", value);
  }

  /*  let handlePrivacyAccettata = useCallback((value) => {
    console.log("privacy accettata:: ", value);*/
  /*  async function handlePrivacyAccettata(value) {
    let accettazionePrecedente = _isPrivacyAccettata;
    let accettazioneSuccessiva = _isPrivacyAccettata;
    if (accettazionePrecedente === true) {
      accettazioneSuccessiva = false;
    } else if (accettazionePrecedente === false) {
      accettazioneSuccessiva = true;
    } else {
      accettazioneSuccessiva = false;
    }
    setIsPrivacyAccettata(accettazioneSuccessiva);
  }*/
  /*  cambiaPrivacy();
  }, []);*/

  /* let handleInviaRichiesta = async (evt) => {
     evt.preventDefault();
     let dataTarget = evt.target;
     window.scrollTo(0, 0);
     setReloaded(true);
   
     let dataToSend = await buildDataRichiestaAppuntamento(
       dataTarget,
       window.location.origin);
     setDataToSend(dataToSend);
   
     let checkRecaptcha = await checkApiResponseRecaptcha(_resCatpcha);
     setResponseIAmNotARobot(checkRecaptcha);
     // coi funziona: provo con useEffect 
     if (checkRecaptcha !== null &&
       checkRecaptcha.data !== null) {
       if (checkRecaptcha.code === "200") {
         setResponseRichiestaAppuntamento(await setApiAppuntamento(dataToSend));
       }
       else {
         let dataBoxFeeback = {
           typeFeedback: "error",
           titleFeedback: "Richiesta non ricevuta",
           subtitleFeedback: "[Errore validazione captcha]",
           messageFeedback: "Dettagli: verificare il captcha"
         };
         setDataBoxFeeback(dataBoxFeeback);
       }
       setResCatpcha(null);
       setIAmNotARobot(false);
   
     }
   };
  */
  let _disabledBottoneDona = !_presoVisione || !_isEmailValid || !_isEmailDiControlloValid || !_isCodiceFiscaleValid || _isTelefonoValid === false || _causale === null;

  async function onChangeNome(value) {
    setNome(value);
    sessionStorage.setItem("_nome", value);
  }
  async function onChangeCognome(value) {
    setCognome(value);
    sessionStorage.setItem("_cognome", value);
  }
  async function onChangeAltro(value) {
    setAltro(value);
    sessionStorage.setItem("_altro", value);
  }
  async function onChangeCodiceFiscale(value) {
    setCodiceFiscale(value);
    sessionStorage.setItem("_codiceFiscale", value);
  }
  async function onChangeTelefono(value) {
    setTelefono(value);
    sessionStorage.setItem("_telefono", value);
  }
  async function onChangeEmail(value) {
    setEmail(value);
    sessionStorage.setItem("_email", value);
  }
  async function onChangeIndirizzo(value) {
    setIndirizzo(value);
    sessionStorage.setItem("_indirizzo", value);
  }
  /*COMBO BOX*/
  async function onChangeCausale(value) {
    handleCausale(value);
    sessionStorage.setItem("_causale", value);
  }

  //render
  //https://react-bootstrap.github.io/layout/grid/
  return (
    <>
      {_pathToRedirectOk != null ? <Redirect to={_pathToRedirectOk} /> : null}
      {_richiestaOk ? <Redirect to="/ppresoincarico" /> : _richiestaOk === false ? <Redirect to="/pannullato" /> : null}

      <div className="row col-12 d-flex flex-row formRichiesta">
        <Container className="px-4 my-4" id="donazione" tag="section">
          <form formMethod="POST" id="formPagamento" onSubmit={handleSubmit}>
            <Row tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
              <Col className="px-lg-4 py-lg-2" lg={6} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
                <Input
                  id="inputNomeDonatore"
                  name="nomeDonatore"
                  maxLength="125"
                  label={"Nome: *".toUpperCase()}
                  type="text"
                  value={_nome}
                  required={true}
                  onChange={(event) => {
                    onChangeNome(event.target.value);
                  }}
                />
              </Col>
              <Col className="px-lg-4 py-lg-2" lg={6} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
                <Input
                  id="inputCognomeDonatore"
                  name="cognomeDonatore"
                  maxLength="125"
                  label={"Cognome: *".toUpperCase()}
                  type="text"
                  value={_cognome}
                  required={true}
                  onChange={(event) => {
                    onChangeCognome(event.target.value);
                  }}
                />
              </Col>
              {/* ROw and cols dentro email*/}
              <Email
                idEmail="inputEmailDonatore"
                idEmailDiControllo="inputEmailDiControlloDonatore"
                nameEmail="emailDonatore"
                maxLength="250"
                nameEmailDiControllo="emailDiControlloDonatore"
                email={_email}
                emailDiControllo={_emailDiControllo}
                isRequired={true}
                colsLeft={6}
                vaiACapoLoStesso={true}
                onChangeEmail={(event) => {
                  onChangeEmail(event.target.value);
                }}
                onChangeEmailDiControllo={(event) => {
                  setEmailDiControllo(event.target.value);
                }}
                notifyRecapiti={notifyRecapiti}
                validOnEnter={true}
              />
              <Col className="px-lg-4 py-lg-2" lg={6} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
                <CInput
                  id="inputCodiceFiscaleDonatore"
                  name="codiceFiscaleDonatore"
                  maxLength="16"
                  label={"Codice Fiscale:".toUpperCase()}
                  type="text"
                  value={_codiceFiscale}
                  handleNotifyValid={notifyCodiceFiscaleValid}
                  re={_reCf}
                  onChange={(event) => {
                    onChangeCodiceFiscale(event.target.value.toUpperCase());
                  }}
                  isRequired={true}
                  messaggioOk={_messaggioCfOk}
                  messaggioWarning={_messaggioCfWarning}
                  validOnEnter={true}
                />
              </Col>

              <Col className="px-lg-4 py-lg-2" lg={6} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
                <Input
                  id="inputImportoDaDonare"
                  name="importoDaDonare"
                  label={"Importo".toUpperCase() + "[€]: *"}
                  type="number"
                  value={_importo}
                  required={true}
                  onChange={(event) => {
                    checkImporto(event.target.value);
                  }}
                />
              </Col>

              <Col className="px-lg-4 py-lg-2" lg={6} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
                {" "}
                <CSelect
                  id="selectCausaleDonazione"
                  name="causaleDonazione"
                  labelText={"Causale: *".toUpperCase()}
                  required={true}
                  selectedOption={_causale}
                  multiOptions={_itemsCausaleDonazione}
                  disabled={!_loadedItemsCausaleDonazione}
                  handleChange={onChangeCausale}
                  search={true}
                  reset={false}
                  defaultValue={_causale}
                />
              </Col>
              <Col className="px-lg-4 py-lg-2" lg={6} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
                <Input
                  id="inputAltro"
                  name="areaindicata"
                  maxLength="125"
                  label={"Area: ".toUpperCase()}
                  type="text"
                  value={_altro}
                  required={false}
                  onChange={(event) => {
                    onChangeAltro(event.target.value);
                  }}
                />
              </Col>

              <Col className="px-lg-4 py-lg-2" lg={6} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
                <CSelect
                  id="selectProvincia"
                  name="provinciaDonatore"
                  labelText={"Provincia: ".toUpperCase()}
                  selectedOption={_provincia}
                  multiOptions={_itemsProvince}
                  disabled={!_loadedItemsProvince}
                  handleChange={handleProvincia}
                  search={true}
                  reset={false}
                />
              </Col>

              <Col className="px-lg-4 py-lg-2" lg={6} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
                <CSelect
                  id="selectComune"
                  name="comuneDonatore"
                  labelText={"Comune: ".toUpperCase()}
                  selectedOption={_comune}
                  multiOptions={_itemsComuni}
                  disabled={!_loadedItemsComuni}
                  handleChange={handleComune}
                  search={true}
                  reset={false}
                />
              </Col>

              <Col className="px-lg-4 py-lg-2" lg={6} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
                <Input
                  id="inputIndirizzoDonatore"
                  name="indirizzoDonatore"
                  maxLength="200"
                  label={"Indirizzo:".toUpperCase()}
                  type="text"
                  value={_indirizzo}
                  required={false}
                  onChange={(event) => {
                    onChangeIndirizzo(event.target.value);
                  }}
                />
              </Col>

              <Col className="px-lg-4 py-lg-2" lg={6} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
                <CInput
                  id="inputTelefonoDonatore"
                  name="telefonoDonatore"
                  maxLength="50"
                  label={"Telefono/Cellulare: ".toUpperCase()}
                  type="tel"
                  value={_telefono}
                  handleNotifyValid={notifyTelefonoValid}
                  re={_reTel}
                  onChange={(event) => {
                    onChangeTelefono(event.target.value);
                  }}
                  isRequired={false}
                  messaggioOk={_messaggioTelOk}
                  messaggioWarning={_messaggioTelWarning}
                  validOnEnter={true}
                />
              </Col>
            </Row>

            {/*
            <Row tag="div" className="justify-content-center" lg={2} widths={["xs", "sm", "md", "lg", "xl"]}>
              <Col className="px-lg-4 py-lg-4" lg={6} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
                Per procedere leggi la normativa sulla privacy
              </Col>
              <Col className="px-lg-4 py-lg-2" lg={6} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
                <Card>
                  <Button color="success" icon={false} tag="button" size="md" onClick={downloadPrivacy} disabled={!_abilitatoBottonePrivacy}>
                    <Icon className="" color="white" icon="it-download" padding={false} size="sm" />
                    &nbsp;Scarica il documento
                    {!_abilitatoBottonePrivacy && (
                      <span>
                        <Progress color="success" indeterminate={true} role="progressbar" tag="div" />
                      </span>
                    )}
                  </Button>
                </Card>
              </Col>
                    </Row>*/}
            {
              //_mostraAccettaPrivacy === true ? (
              //offset e span non funziona, vcchia maniera
              <>
                <Row tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
                  <Col className="px-lg-4 py-lg-2 justify-content-center" lg={1}></Col>
                  <Col className="px-lg-2 py-lg-2 justify-content-start" lg={10} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
                    <Card className="m-3 text-left noteFormSM">
                      <CardText tag="i">
                        [*] campi obbligatori
                        <br />
                        Per procedere &egrave; necessario inserire valori corretti nei campi compilati e prendere visione dell'informativa privacy
                      </CardText>
                    </Card>
                    <FormGroup check className="m-3" tag="div">
                      <Label className="pr-lg-3 float-left" check for="id_presoVisioneDonatore" tag="label" widths={["xs", "sm", "md", "lg", "xl"]}>
                        Ho preso visione dell'&nbsp;
                        <Link
                          to={{
                            pathname: "/privacycookie",
                            search: "",
                            hash: "",
                            state: {},
                          }}
                          innerRef={React.createRef()}
                          target="_blank"
                        >
                          informativa
                        </Link>
                      </Label>
                      <div className="float-left" title="click per presa visione">
                        <Toggle
                          // label="Ho letto e compreso l'informativa, e acconsento al trattamento dei dati"
                          label=""
                          id="id_presoVisioneDonatore"
                          name="presoVisioneDonatore"
                          onChange={(e) => handlePresoVisione(e.target.checked)}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
                  <Col className="px-lg-4 py-lg-2 justify-content-center" lg={1}></Col>
                  <Col className="px-lg-4 py-lg-2 justify-content-start" lg={10} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
                    <Card className="text-left">
                      Il sottoscritto dichiara di aver preso visione dell'informativa ai sensi dell'art. 13 del Regolamento e di acconsentire al trattamento dei dati personali
                      <FormGroup check inline tag="div">
                        <Input
                          id="acconsento_si"
                          name="acconsentoDonatore"
                          defaultChecked
                          type="radio"
                          value="SI"
                          required={false}
                          onChange={(event) => {
                            handleAcconsento(event.target.value);
                          }}
                        />
                        <Label className="pr-lg-5" check for="acconsento_si" tag="label" widths={["xs", "sm", "md", "lg", "xl"]}>
                          Acconsento
                        </Label>
                        <Input
                          id="acconsento_no"
                          name="acconsentoDonatore"
                          type="radio"
                          required={false}
                          value="NO"
                          onChange={(event) => {
                            handleAcconsento(event.target.value);
                          }}
                        />
                        <Label check for="acconsento_no" tag="label" widths={["xs", "sm", "md", "lg", "xl"]}>
                          Non Acconsento
                        </Label>
                      </FormGroup>
                    </Card>
                  </Col>
                </Row>
                <Row tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
                  <Col className="px-lg-4 py-lg-2 justify-content-center" lg={1}></Col>

                  <Col className="px-lg-4 py-lg-2 justify-content-center" lg={10} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
                    <Card className="text-center">
                      <div className="divBtnInviaSM">
                        <Button id="btnInvia" name="btnInvia" color="primary" disabled={_disabledBottoneDona} type="submit" onClick={() => {}} icon={false}>
                          {
                            //<Icon color="white" icon="it-upload" padding={true} size="lg" />
                          }
                          <h2>{_labelBottoneDona}</h2>
                        </Button>
                      </div>{" "}
                    </Card>
                  </Col>
                </Row>
              </>
              //) : null
            }
          </form>
        </Container>
      </div>
    </>
  );
}
//                  <Icon className="" color="white" icon="it-download" padding={false} size="sm" style={{ marginRight: "5px" }} />

export default withRouter(HomePage);
