import React, { useState, useCallback } from "react";
import { CookieBar, CookieBarButtons, CookieBarButton } from "design-react-kit";
import { Link } from "react-router-dom";

export function CCookieBar(props) {
  //passare a session storage
  const [_cookieAccettati, setCookieAccettati] = useState(sessionStorage.getItem("sessionCookieAccettati"));

  const handleAccetto = useCallback((item) => {
    sessionStorage.setItem("sessionCookieAccettati", true);
    setCookieAccettati(sessionStorage.getItem("sessionCookieAccettati"));
  }, []);

  if (_cookieAccettati === true) return null;
  else {
    return (
      <CookieBar hidden={!!_cookieAccettati}>
        <p>
          Questo sito utilizza cookie tecnici. <br />
          Proseguendo nella navigazione accetti l’utilizzo dei cookie.
        </p>
        <CookieBarButtons>
          <CookieBarButton onClick={handleAccetto} className="float-left">
            <Link
              to={{
                pathname: "/privacycookie",
                search: "",
                hash: "",
                state: {},
              }}
              innerRef={React.createRef()}
              target="_blank"
            >
              <CookieBarButton>
                Preferenze<span className="sr-only"> cookies</span>
              </CookieBarButton>
            </Link>{" "}
          </CookieBarButton>
          <CookieBarButton onClick={handleAccetto} className="float-left">
            Accetto<span className="sr-only"> i cookies</span>
          </CookieBarButton>
        </CookieBarButtons>
      </CookieBar>
    );
  }
}

export default CCookieBar;
