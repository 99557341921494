import React from "react";
import { useEffect, useState } from "react";
import Select from "react-select";
import { FormGroup } from "design-react-kit";

import "../../../styles/cSelectStyle.css";

function CSelect(props) {
  const options = props.multiOptions;

  const state = {
    selectedOption: null,
    placeholder: "Scegli un'opzione",
    isDisabled: false,
    isSearchable: false,
    isMulti: false,
    isClearable: true,
  };
  const [_selectedOption, setSelectedOption] = useState(null);
  const [_optionValueForRequired, setOptionValueForRequired] = useState(null);

  /*
        
    
        useEffect(() => {
            async function sss() {
                if (props.defaultValue !== null &&
                    typeof (props.defaultValue) !== 'undefined') {
                    console.log("fff props.selectedOption: ", props.selectedOption);
                    console.log("fff props.defaultValue: ", props.defaultValue);
                    setSelectedOption(props.defaultValue);
                    await props.handleChange(props.defaultValue);
                }
            }
            sss();
        }, [props.defaultValue]);
    
        useEffect(() => {
            async function sss() {
                console.log("fff props.selectedOption 2222: ", props.selectedOption);
                if (_selectedOption !== null &&
                    typeof (_selectedOption) !== 'undefined') {
                    console.log("fff props.selectedOption 2222: ", props.selectedOption);
                    setSelectedOption(_selectedOption);
                    await props.handleChange(_selectedOption);
                }
            }
            sss();
        }, [_selectedOption]);
    */
  /*
        useEffect(() => {
            async function sss() {
                if (_selectedOption !== null) {
                    setSelectedOption(props.defaultValue);
                }
            }
            sss();
        }, [_selectedOption]);
    */
  //let _value = props.selectedOption !== null ? props.selectedOption : "";

  /*  useEffect(() => {
    async function sss() {
      if (props.defaultValue !== null && typeof props.defaultValue !== "undefined") {
        console.log("fff props.selectedOption: ", props.selectedOption);
        console.log("fff props.defaultValue: ", props.defaultValue);
        setSelectedOption(props.defaultValue);
        await props.handleChange(props.defaultValue);
      }
    }
    sss();
  }, [props.defaultValue]);
*/
  useEffect(() => {
    async function _setSelectedOption() {
      if (props.selectedOption !== null && typeof props.selectedOption !== "undefined") {
        setSelectedOption(props.selectedOption);
        setOptionValueForRequired(props.selectedOption.value);
      }
    }
    _setSelectedOption();
  }, [props.selectedOption]);

  return (
    <>
      <>{props.title}</>
      <div className="bootstrap-select-wrapper">
        <label htmlFor={props.name}>{props.labelText}</label>
        <FormGroup>
          <Select
            id={props.name}
            name={props.name}
            value={_selectedOption}
            //defaultValue={props.defaultValue}
            callbackFunction={props.callbackFunction}
            onChange={(value) => {
              props.handleChange(value);
            }}
            options={options}
            placeholder={state.placeholder}
            isDisabled={!!props.disabled}
            isSearchable={!!props.search}
            isMulti={!!props.multi}
            isClearable={!!props.reset}
            aria-label={props.placeholder}
            classNamePrefix={"react-select"}
            className={"cSelect"}
          />
        </FormGroup>
        {!props.disabled && !!props.required ? (
          <input
            tabIndex={-1}
            autoComplete="off"
            style={{ opacity: 0, height: 0, position: "absolute" }}
            value={_optionValueForRequired}
            required={!!props.required}
            onChange={() => {}}
          />
        ) : null}
      </div>
    </>
  );
}

export default CSelect;
