import React from "react";
import { withRouter } from "react-router";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
//import "../../../styles/index.css"
import BoxFeedback from "./BoxFeedback";

async function hasContent(toCheck) {
  return toCheck !== null && typeof toCheck !== "undefined" && toCheck.trim() !== "";
}

function FeedbackPage(props) {
  let originFeedback = props.originFeedback;
  let typeFeedback = props.typeFeedback;
  let titleFeedback = props.titleFeedback;
  let subtitleFeedback = props.subtitleFeedback;
  let messageFeedback = props.messageFeedback;

  let colorAlert = typeFeedback === "success" ? "success" : "danger";

  return (
    <div className="row col-12 d-flex flex-row">
      <Container className="px-4 my-4" id="intro" tag="section">
        <Row tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
          <Col className="px-lg-4 py-lg-2" lg={2} />
          <Col className="px-lg-4 py-lg-2" lg={8} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
            <BoxFeedback
              titleFeedback={titleFeedback}
              colorAlert={colorAlert}
              originFeedback={originFeedback}
              subtitleFeedback={subtitleFeedback}
              messageFeedback={messageFeedback}
            />
          </Col>
          <Col className="px-lg-4 py-lg-2" lg={2} />
        </Row>
        {props.mostraTornaAllaPagina && (
          <Link
            to={{
              pathname: props.pathTornaAllaPagina,
              search: "",
              hash: "",
              state: {},
            }}
            replace
            innerRef={React.createRef()}
            role="button"
            className="btn btn-light"
          >
            {props.testoTornaAllaPagina}
          </Link>
        )}
      </Container>
    </div>
  );
}

export default withRouter(FeedbackPage);
