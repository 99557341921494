import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Card } from "reactstrap";
import queryString from "query-string";
import { pagamentoOk } from "../../businesslogic/ApiInterface";
import { Link } from "react-router-dom";

function hasContent(toCheck) {
  return toCheck !== null && typeof toCheck !== "undefined" && toCheck.trim() !== "";
}

function getContent(toCheck) {
  return hasContent(toCheck) ? toCheck : "";
}

function PPresoInCarico(props) {
  sessionStorage.removeItem("_nome");
  sessionStorage.removeItem("_cognome");
  sessionStorage.removeItem("_codiceFiscale");
  sessionStorage.removeItem("_telefono");
  sessionStorage.removeItem("_email");
  sessionStorage.removeItem("_causale");
  sessionStorage.removeItem("_indirizzo");
  sessionStorage.removeItem("_provincia");
  sessionStorage.removeItem("_comune");

  //path params
  let _idppp = getContent(props.match.params.idppp);
  let _mmm = getContent(props.match.params.mmm);
  let _ccc = getContent(props.match.params.ccc);

  let _queryParams = queryString.parse(props.location.search);
  const [_toSend, setToSend] = useState(null);
  const [_response, setResponse] = useState(null);

  useEffect(() => {
    async function _setToSend() {
      let toSend = {
        ..._queryParams,
        ...{
          idppp: _idppp,
          mmm: _mmm,
          ccc: _ccc,
        },
      };
      setToSend(toSend);
    }
    _setToSend();
  }, []);

  useEffect(() => {
    async function _send() {
      if (_toSend !== null && typeof _toSend !== "undefined") {
        console.log("_toSend", _toSend);
        let response = await pagamentoOk(_toSend);
        setResponse(response);
      }
    }
    _send();
  }, [_toSend]);

  return (
    <div className="row col-12 d-flex flex-row">
      <Container className="px-4 my-4" id="donazione" tag="section">
        <Row tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
          <Col className="px-lg-4 py-lg-2 feedCol" lg={12} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
            <h1>
              La donazione è andata a buon fine
              <br />
              <br />
              Grazie per aver scelto di sostenere il <br /> Policlinico San Martino di Genova
              <br />
            </h1>
          </Col>
        </Row>
        <Row tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
          <Col className="px-lg-4 py-lg-2 feedCol" lg={3} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}></Col>
          <Col className="px-lg-4 py-lg-2 feedCol" lg={6} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
            {
              //_response === null ? <h3>Aggiornamento in corso....</h3> : null
            }
            <Link
              to={{
                pathname: "/tornahp",
                search: "",
                hash: "",
                state: {},
              }}
              replace
              innerRef={React.createRef()}
              role="button"
              className="btn col-12 btnFeed"
            >
              Torna alla home page
            </Link>{" "}
            <br />
          </Col>
          <Col className="px-lg-4 py-lg-2 feedCol" lg={3} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}></Col>
        </Row>{" "}
        <Row tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
          <Col className="px-lg-4 py-lg-2 feedCol" lg={12} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
            <h1>Con il tuo contributo la nostra ricerca diventa cura</h1>
            <br />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default withRouter(PPresoInCarico);

/*        <Row tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
          <Col className="px-lg-4 py-lg-2 feedCol" lg={12} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
            <br />
            <h1>Con il tuo contributo la nostra ricerca diventa cura</h1> 
          </Col>
        </Row>*/
