import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Card } from "reactstrap";
import queryString from "query-string";
import { pagamentoKo } from "../../businesslogic/ApiInterface";
import { Link } from "react-router-dom";

function hasContent(toCheck) {
  return toCheck !== null && typeof toCheck !== "undefined" && toCheck.trim() !== "";
}

function getContent(toCheck) {
  return hasContent(toCheck) ? toCheck : "";
}

function PAnnullato(props) {
  //path params
  let _idppp = getContent(props.match.params.idppp);
  let _mmm = getContent(props.match.params.mmm);
  let _ccc = getContent(props.match.params.ccc);

  let _queryParams = queryString.parse(props.location.search);

  //  queryParams.name = value
  //lab01.liguriadigitale.it:8080/smdonazioni/ppresoincarico/
  /* 181?
  alias=Donazioni-SM&
  codTrans=0000000181&
  importo=15000&
  codAut&
  mac=9bcd1050b69bf057f67d941ea764e0bedd5948aa&
  brand&
  tContab=I&
  esito=ATTESA_RT&
  divisa=EUR&
  data&
  orario&
  email=ferrando.genova@gmail.com&
  cognome=Fabrizio+-+Ferrando&
  nome=Fabrizio+-+Ferrando&
  IUV=0020000000000000000000000009568&
  uidriscossione&
  ParametriAggiuntivi*/
  // <Route path="/pannullato/:idppp?/:iii?/:mmm?/:ccc?" component={PAnnullato} />
  /* pannullato/161?
    alias=Donazioni-SM&
    codTrans=0000000161&
    importo=1000&
    codAut&
    mac=2d6d8e9c462c4fa4479bcbaf8f9a745232cfbb8e&
    brand&
    tContab=I&
    esito=ANNULLO&
    divisa=EUR&
    data&
    orario&
    email=ferrando.genova@gmail.com&
    cognome=Fabrizio+-+Ferrando&
    nome=Fabrizio+-+Ferrando&
    IUV=0020000000000000000000000009467&
    uidriscossione&
    ParametriAggiuntivi
    idppp:,
          iii:,
          mmm:,
          ccc:,
*/
  const [_toSend, setToSend] = useState(null);
  const [_response, setResponse] = useState(null);

  useEffect(() => {
    async function _setToSend() {
      let toSend = {
        ..._queryParams,
        ...{
          idppp: _idppp,
          mmm: _mmm,
          ccc: _ccc,
        },
      };
      setToSend(toSend);
    }
    _setToSend();
  }, []);

  useEffect(() => {
    async function _send() {
      if (_toSend !== null && typeof _toSend !== "undefined") {
        console.log("_toSend", _toSend);
        let response = await pagamentoKo(_toSend);
        setResponse(response);
      }
    }
    _send();
  }, [_toSend]);

  return (
    <div className="row col-12 d-flex flex-row">
      <Container className="px-4 my-4" id="donazione" tag="section">
        <Row tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
          <Col className="px-lg-4 py-lg-2 feedCol" lg={12} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
            <h1>
              La donazione non è andata a buon fine
              <br />
              Pagamento non avvenuto
            </h1>
          </Col>
        </Row>
        <Row tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
          <Col className="px-lg-4 py-lg-2 feedCol" lg={3} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}></Col>
          <Col className="px-lg-4 py-lg-2 feedCol" lg={6} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
            {
              // _response === null ? <h3>Aggiornamento in corso....</h3> : null
            }
            <Link
              to={{
                pathname: "/",
                search: "",
                hash: "",
                state: {},
              }}
              replace
              innerRef={React.createRef()}
              role="button"
              className="btn col-12 btnFeed btnFeedAnnullato"
            >
              Prova di nuovo
            </Link>{" "}
            <br />
          </Col>
          <Col className="px-lg-4 py-lg-2 feedCol" lg={3} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}></Col>
        </Row>{" "}
      </Container>
    </div>
  );
}
export default withRouter(PAnnullato);

/* 
        <Row tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
          <Col className="px-lg-4 py-lg-2 feedCol" lg={12} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
            <h2>Per informazioni scrivere a donazioni@loremloremlorem.lorem</h2>
          </Col>
        </Row>
<FeedbackPanel
        titleFeedback="Pagamento non avvenuto"
        typeFeedback="danger"
        originFeedback=""
        subtitleFeedback="rifiutato o annullato"
        messageFeedback="Il tentativo di pagamento &egrave; stato interrotto. Per informazioni contattare l'amministratore di sistema."
        mostraTornaAllaPagina={true}
        testoTornaAllaPagina="Clicca qui per ritentare il pagamento"
        pathTornaAllaPagina="/"
      />
      */
