import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { Button, Icon, Input, FormGroup, Toggle, Label, Progress, CardText, CardSignature } from "design-react-kit";
import { Container, Row, Col, Card } from "reactstrap";

function Privacy() {
  return (
    <div className="row col-12 d-flex flex-row align-content-flex-start">
      https://www.ospedalesanmartino.it/it/privacy-cookie-policy-donazioni
      <Container className="px-4 my-4" id="donazionePrivacy" tag="section">
        <Row tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
          <Col className="px-lg-4 py-lg-2" lg={12} tag="div" widths={["xs", "sm", "md", "lg", "xl"]}>
            <h2>
              <strong>Privacy e cookie policy</strong>
            </h2>
            <p>
              <strong>
                INFORMAZIONI SUL TRATTAMENTO DEI DATI PERSONALI degli utenti delle pagine "Sostienici"  e 5xmille del sito web dell’IRCCS Ospedale Policlinico ai sensi
                dell'articolo 13 del Regolamento (UE) 2016/679 – Regolamento Generale sulla Protezione dei Dati (GDPR)
              </strong>
            </p>
            <p>
              La presente policy in materia di privacy illustra e descrive le modalità di trattamento e raccolta dei dati personali degli utenti che consultano il
              sito https://www.ospedalesanmartino.it/sostienici.html, il sito 5xmille.ospedalesanmartino.it  e che compilano i form presenti sul sito stesso o inviati tramite la
              mail di contatto donazioni@hsanmartino.it, o che visitano il sito web.
            </p>
            <ol>
              <li>
                <strong> Titolare del trattamento</strong>
              </li>
            </ol>
            <p>
              Titolare del trattamento è l' IRCCS Ospedale Policlinico San Martino (da qui in avanti Policlinico), in persona del Direttore Generale, legale rappresentante{" "}
              <em>pro tempore</em>, con sede in Largo Rosanna Benzi 10 – 16132 Genova.
              <br /> Delegati/Responsabili interni del trattamento sono i dirigenti delle strutture aziendali a cui l'interessato si può rivolgere per richiedere informazioni
              relative ai dati personali. Autorizzati/Incaricati del trattamento sono i funzionari e gli impiegati autorizzati a compiere le operazioni dal titolare o dai
              responsabili.
            </p>
            <ol start="2">
              <li>
                <strong> Responsabile per la Protezione dei Dati (R.P.D.)</strong>
                <br /> Il Responsabile della protezione dei dati è contattabile all’indirizzo email <a href="mailto:dpo@hsanmartino.it">dpo@hsanmartino.it</a>
              </li>
              <li>
                <strong> Responsabile del trattamento</strong>
                <br /> Il Responsabile del trattamento dei dati è il Direttore Generale <em>pro tempore</em> del Policlinico.
                <br /> Liguria Digitale S.p.A. è responsabile esterno del trattamento, ai sensi dell'articolo 28 del GDPR, in quanto incaricata della manutenzione tecnologica del
                sito.
              </li>
              <li>
                <strong> Modalità di trattamento dei dati e sicurezza </strong>
              </li>
            </ol>
            <p>
              Il trattamento dei dati connessi agli utenti è effettuato per consentire ai soggetti che compilano il <em>form</em> di restare in contatto e ricevere messaggi e
              informazioni sulle attività dell'Ente, per permettere agli Utenti di effettuare una donazione,  per consentire al Policlinico di inviare informazioni e news sulle
              attività di ricerca, aggiornamenti sui progetti di ricerca scientifica e sanitaria, invitare ad eventi e iniziative, informare su campagne e attività a supporto del
              Policlinico stesso tramite le pagine web. 
            </p>
            <p>
              Il Titolare tratta i dati degli Utenti adottando le opportune misure di sicurezza per impedire l’accesso, la divulgazione, la modifica o la distruzione non
              autorizzate dei Dati personali. Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalità organizzative e con logiche strettamente
              correlate alle finalità indicate.
            </p>
            <p>
              L'invio facoltativo, esplicito e volontario di dati personali (es: anagrafici, recapiti postali e telefonici, indirizzi di posta elettronica, codice fiscale) ne
              comporta l’acquisizione da parte del Policlinico che li utilizza, previo consenso dell’utente come qui descritto, per gli scopi sopra indicati.
            </p>
            <p>
              I dati inviati spontaneamente per posta elettronica all'indirizzo indicato sul sito <a href="https://5xmille.ospedalesanmartino.it/">ospedalesanmartino.it</a>
              /sostienici e sul sito 5xmille.ospedalesanmartino.it comportano la successiva acquisizione dell'indirizzo del mittente necessario per rispondere alle richieste, per 
              registrare la donazione e determinano anche la raccolta degli eventuali altri dati personali inseriti nella mail. Il loro mancato conferimento può comportare
              l'impossibilità di ottenere quanto richiesto
            </p>
            <ol start="5">
              <li>
                <strong> Luogo di trattamento dei dati.</strong>
              </li>
            </ol>
            <p>
              Il trattamento ha luogo nelle sedi del Policlinico ed è curato da personale interno autorizzato al trattamento. I dati personali forniti dagli utenti che accedono ai
              servizi web presenti nelle aree interattive del sito (ad es. form per effettuare una donazione, ricevere promemoria e informazioni) saranno utilizzati per le sole
              finalità indicate. In caso di necessità, i dati connessi ai servizi possono essere trattati dal personale della redazione della società Liguria Digitale S.p.A. nelle
              proprie sedi.
            </p>
            <ol start="6">
              <li>
                <strong> Dati di navigazione</strong>
              </li>
            </ol>
            <p>
              I sistemi informatici e le procedure <em>software</em> preposte al funzionamento dei siti del <em>network</em> del Policlinico acquisiscono, nel corso del loro
              normale esercizio, alcuni dati personali la cui trasmissione è implicita nell'uso dei protocolli di comunicazione di internet. In questa categoria di dati rientrano
              gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono al sito, gli indirizzi in notazione URI (
              <em>Uniform Resource Identifier</em>) delle risorse richieste, l'orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del
              file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.) e altri parametri relativi al sistema
              operativo e all'ambiente informatico dell'utente. Questi dati vengono utilizzati al fine di controllare il corretto funzionamento dei siti e per ottenere informazioni
              statistiche sull'uso dei servizi (pagine più visitate, numero di visitatori per fascia oraria o giornaliera, aree geografiche di provenienza, ecc.). I dati vengono di
              norma cancellati quindici giorni dopo l'elaborazione, ma potranno essere conservati per un mese sui server. I dati potrebbero essere utilizzati per l'accertamento di
              responsabilità in caso di ipotetici reati informatici ai danni del sito.
            </p>
            <ol start="7">
              <li>
                <strong> Cookie</strong>
                <br />
                <a href="https://www.ospedalesanmartino.it/">ospedalesanmartino.it</a>utilizza cookie tecnici per migliorare il servizio. In particolare utilizza:
              </li>
            </ol>
            <ul>
              <li>
                <em>cookie</em> di navigazione e di sessione, che garantiscono la normale navigazione e fruizione del sito web;
              </li>
              <li>
                <em>cookie</em> di funzionalità, che permettono all'utente la navigazione in funzione di una serie di criteri selezionati (ad esempio, la lingua).
              </li>
            </ul>
            <p>
              Nel corso della navigazione sul sito, l'utente, se accetta, può ricevere sul suo terminale anche <em>cookie</em> di marketing e analitiche che vengono inviati da
              terze parti: siti o web server diversi dal sito su cui si sta navigando, come nel caso di <em>Google Analytics</em> e del <em>social network Facebook</em>. I dati
              connessi alla navigazione, raccolti attraverso i <em>cookies</em> accettati dall'utente, possono essere trattati per fini di analisi statistiche, analitiche e di
              marketing da parti terze che collaborano con il Policlinico San Martino. Per l'installazione di <em>cookie</em> tecnici non è richiesto il preventivo consenso degli
              utenti, mentre resta fermo l'obbligo di dare l'informativa ai sensi dell'art. 13 del Regolamento (UE) 2016/679. Se si desidera disabilitare una o più tipologie di 
              <em>cookie</em> per questo sito occorre accedere alle impostazioni del browser che si utilizza. Per maggiori informazioni sui <em>cookies analytics</em> e di
              pubblicità di terze parti  :
            </p>
            <ul>
              <li>
                Google: privacy policy cookie - 
                <a href="https://www.google.com/intl/it_it/policies/technologies/cookies/">https://www.google.com/intl/it_it/policies/technologies/cookies/</a>
              </li>
              <li>
                Google Analytics : <a href="http://www.google.com/intl/it/policies/privacy/">http://www.google.com/intl/it/policies/privacy/</a>
              </li>
              <li>
                Facebook: privacy policy cookie - <a href="https://www.facebook.com/policies/cookies/">https://www.facebook.com/policies/cookies/</a>
              </li>
              <li>
                YouTube: privacy policy cookie - <a href="https://www.google.it/intl/it/policies/privacy/">https://www.google.it/intl/it/policies/privacy/</a>
              </li>
            </ul>
            <p>
              <strong>
                Come disabilitare i cookie
                <br />
              </strong>
              In caso di accettazione dei cookies, successivamente, avrete la possibilità di rifiutarli, modificando le impostazioni del browser utilizzato per navigare.
            </p>
            <p>
              Se si utilizza Chrome: <a href="https://support.google.com/chrome/answer/95647?hl=it">https://support.google.com/chrome/answer/95647?hl=it</a>
              <br /> Se si utilizza Firefox: <a href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie">https://support.mozilla.org/it/kb/Gestione%20dei%20cookie</a>
              <br /> Se si utilizza Internet Explorer:  
              <a href="https://support.microsoft.com/it-it/help/17442/windows-internet-explorer-delete-manage-cookies">
                https://support.microsoft.com/it-it/help/17442/windows-internet-explorer-delete-manage-cookies
              </a>
              <br /> Se si utilizza Opera: <a href="http://help.opera.com/Windows/10.00/it/cookies.html">http://help.opera.com/Windows/10.00/it/cookies.html</a>
              <br /> Se si utilizza Safari: <a href="http://support.apple.com/kb/HT1677?viewlocale=it_IT">http://support.apple.com/kb/HT1677?viewlocale=it_IT</a>
              <br /> Se si utilizza Edge: 
              <a href="https://privacy.microsoft.com/it-it/windows-10-microsoft-edge-and-privacy">https://privacy.microsoft.com/it-it/windows-10-microsoft-edge-and-privacy</a>
              <br /> Se si utilizza qualsiasi altro browser cercare nelle “impostazioni” le modalità di gestione dei cookie.
            </p>
            <ol start="8">
              <li>
                <strong> Sicurezza e conservazione dei dati </strong>
              </li>
            </ol>
            <p>
              Il Policlinico mette in atto misure tecniche e organizzative adeguate a proteggere i suoi dati personali da smarrimento o utilizzo illegale, adottando le opportune
              misure di sicurezza per impedire l’accesso, la divulgazione, la perdita, la modifica, l'uso illegale o la distruzione non autorizzate dei Dati personali. Il
              trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalità organizzative e tecniche strettamente correlate alle finalità indicate.
            </p>
            <p>
              I dati personali conferiti dall'utente verranno conservati per il periodo di tempo necessario alle finalità indicate nella presente informativa e  l'utente può sempre
              richiedere che il Titolare sospenda o rimuova i dati.
              <br /> In particolare i dati relativi alla richiesta di informazioni, aggiornamenti e relativi alle donazioni, verranno conservati fino a revoca del consenso da parte
              dell’utente. Per tutte le altre tipologie di dati verranno conservati per il periodo di tempo indispensabile e comunque non eccedente rispetto a quanto richiesto
              dalla normativa vigente.
            </p>
            <ol start="9">
              <li>
                <strong> Modifiche alla privacy policy</strong>
              </li>
            </ol>
            <p>
              Il Titolare si riserva il diritto di apportare modifiche a questa privacy policy in qualsiasi momento, informando i suoi Utenti in questa pagina. È fortemente
              raccomandato di consultare spesso questa pagina, prendendo come riferimento la data dell’ultima modifica indicata in fondo. Nel caso di mancata accettazione delle
              modifiche apportate alla presente privacy policy, l’Utente è tenuto a cessare l’utilizzo di questo sito Web e richiedere al Titolare la cancellazione dei Dati
              Personali. Salvo quanto diversamente specificato, la precedente privacy policy continuerà ad applicarsi ai Dati Personali sino a quel momento raccolti.
            </p>
            <ol start="10">
              <li>
                <strong> Diritti degli interessati</strong>
              </li>
            </ol>
            <p>
              I soggetti cui si riferiscono i Dati Personali hanno il diritto in qualunque momento di ottenere dal Policlinico, nei casi previsti, l'accesso ai dati personali
              (artt. 15 e ss. del GDPR), di ottenere la conferma dell’esistenza o meno degli stessi presso il Titolare del Trattamento, di conoscerne il contenuto e l’origine, di
              verificarne l’esattezza o chiederne l’integrazione, la cancellazione, l’aggiornamento, la rettifica, la trasformazione in forma anonima o il blocco dei Dati Personali
              trattati in violazione di legge, nonché di opporsi in ogni caso, per motivi legittimi, al loro trattamento ai sensi del Regolamento n°679/2016 (GDPR).
            </p>
            <p>Per  esercitare tali diritti nei confronti del Policlinico l’utente dovrà compilare una specifica istanza che potrà essere:</p>
            <ol>
              <li>
                Consegnata personalmente all'Ufficio Protocollo (Palazzo Amministrazione piano Terra), oppure
                <br /> b.         Spedita tramite raccomandata all'indirizzo: Ufficio Protocollo Ospedale Policlinico San Martino – Largo Rosanna Benzi 10, 16132 – Genova oppure 
                <br /> c.         Spedita tramite PEC a <a href="mailto:protocollo@pec.hsanmartino.it">protocollo@pec.hsanmartino.it</a> riportando in Oggetto: Privacy esercizio
                dei diritti.
              </li>
            </ol>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withRouter(Privacy);
