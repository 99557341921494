import React from "react";
import CommonHeaderSMD from "./CommonHeaderSMD";
import { Container, Row, Col } from "reactstrap";

function HeaderSimpleSMD() {
  return (
    <header className="app-header">
      <Container>
        <Row tag="div" widths={["xs", "sm", "md", "lg"]}>
          <Col className="px-lg-4 py-lg-2" lg={12} widths={["xs", "sm", "md", "lg"]}>
            <CommonHeaderSMD />
          </Col>
        </Row>
      </Container>
    </header>
  );
}

export default HeaderSimpleSMD;
